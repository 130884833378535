import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProjectStatusList() {
  // Use toast
  const toast = useToast();
  const { route, router } = useRouter();

  const refProjectStatusListTable = ref(null);

  // filter options
  const projectStatusOptions = [
    {label: "Administrative", key: "administrative"},
    {label: "Projet", key: "projet"},
  ];

  // Table Handlers
  const tableColumns = [
    { label: "libellé", key: "label", sortable: true },
    { label: "Code", key: "key", sortable: true },
    { label: "Type dépense", key: "expense_nature" },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalProjectStatus = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const expenseNatureFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProjectStatusListTable.value
      ? refProjectStatusListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProjectStatus.value,
    };
  });

  const refetchData = () => {
    refProjectStatusListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, expenseNatureFilter],
    () => {
      refetchData();
    }
  );

  const fetchProjectStatusList = (ctx, callback) => {
    store
      .dispatch("projectStatus/fetchProjectStatusList", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        expenseNature: expenseNatureFilter.value,
      })
      .then((response) => {
        const { project_status, total } = response;
        
        callback(project_status);
        totalProjectStatus.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de la récupération des statuts de projets",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const addProjectStatus = (data) => {
    store
      .dispatch("projectStatus/addProjectStatus", data)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Le statut a été ajouté avec succès.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        router.push({ name: "apps-project-status-list" });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Une erreur est survenue lors de l'ajout du statut de projet",
            icon: "AlertTriangleIcon",
            variant: "error",
          },
        });
      });
  };

  const updateProjectStatus = (data) => {
    store
      .dispatch("projectStatus/updateProjectStatus", data)
      .then((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Le status a été mise à jour avec succès.",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        router.push({ name: "apps-project-status-list" });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Une erreur est survenue lors de la modification du statut de projet",
            icon: "AlertTriangleIcon",
            variant: "error",
          },
        });
      });
  };

  const deleteProjectStatus = async (projectStatusID) => {
    const { data, status } = await store.dispatch("projectStatus/destroyProjectStatus", {
      id: projectStatusID,
    });
    if (status != 200) {
      toast({
        component: ToastificationContent,
        props: {
          title: data.error,
          icon: "AlertTriangleIcon",
          variant: "error",
        },
      });
    } else {
      toast({
        component: ToastificationContent,
        props: {
          title: "Le status a été supprimé avec succès.",
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
    }
  };

  const onSubmit = (data) => {
    if (data.id) {
      updateProjectStatus(data);
    } else {
      addProjectStatus(data);
    }
  };

  return {
    fetchProjectStatusList,
    tableColumns,
    perPage,
    currentPage,
    totalProjectStatus,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProjectStatusListTable,
    refetchData,
    deleteProjectStatus,
    onSubmit,

    expenseNatureFilter,
    projectStatusOptions,
  };
}
