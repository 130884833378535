<template>
  <div>
    <!-- Status Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: label -->
        <b-col cols="12" md="4">
          <b-form-group label="Libellé" label-for="label">
            <b-form-input
              id="label"
              v-model="projectStatusData.label"
              @input="$emit('update:label', $event)"
            />
          </b-form-group>
        </b-col>

        <!-- Field: key -->
        <b-col cols="12" md="4">
          <b-form-group label="Code" label-for="key">
            <b-form-input
              id="key"
              v-model="projectStatusData.key"
              @input="$emit('update:key', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group
            label="Type dépense"
            label-for="project-status-expense-nature"
          >
            <v-select
              v-model="projectStatusData.expense_nature"
              input-id="project-status-expense-nature"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="projectStatusOptions"
              :reduce="(val) => val.key"
              @input="$emit('update:expense_nature', $event)"
            />
          </b-form-group>
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="show"
            class="mb-2"
            style="max-height: 56px !important;"
          >
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              Vos dépenses sont coonsidérées comme des dépenses <b>{{ projectStatusData.expense_nature == 'projet' ? 'Projets' : 'Administratives' }}</b>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="submitData"
      :disabled="isLoading"
    >
      {{ projectStatusData.id ? "Modifier" : "Sauvegarder" }}
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.go(-1)"
    >
      Annuler
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useProjectStatusList from "../project-status-list/useProjectStatusList";
import { computed, ref } from "@vue/composition-api";
import { heightFade } from '@core/directives/animations'

export default {
  name: "HandleProjectStatusTabGeneral",
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BAlert,
    vSelect,
  },
  props: {
    projectStatusData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    'height-fade': heightFade,
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const { projectStatusOptions } = useProjectStatusList();

    const submitData = () => {
      isLoading.value = true;
      emit("saveData");
    };
    const show = computed(() => {
      const expNature = props.projectStatusData.expense_nature
      if(expNature) return true
      else return false
    });

    return {
      submitData,
      projectStatusOptions,
      isLoading,
      show,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
