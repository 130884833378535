<template>
  <component :is="projectStatusData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="projectStatusData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données du statut</h4>
      <div class="alert-body">
        Pas de statut trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-roles-list' }">
          la liste des statuts de projets
        </b-link>
        pour d'autres statuts de projets.
      </div>
    </b-alert>

    <b-tabs v-if="projectStatusData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Général</span>
        </template>
        <handle-project-status-tab-general
          class="mt-2 pt-75"
          :project-status-data.sync="projectStatusData"
          @saveData="onSubmit(projectStatusData)"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleProjectStatusTabGeneral from "./HandleProjectStatusTabGeneral.vue";
import useProjectStatusList from "../project-status-list/useProjectStatusList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HandleProjectStatus",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleProjectStatusTabGeneral,
  },
  setup() {
    // Use toast
    const toast = useToast();
    const { onSubmit } = useProjectStatusList();

    const blankProjectStatusData = {
      label: "",
      key: "",
      expense_nature: null,
    };

    const projectStatusData = ref(JSON.parse(JSON.stringify(blankProjectStatusData)));

    const fetchProjectStatus = () => {
      store
        .dispatch("projectStatus/fetchProjectStatus", { id: router.currentRoute.params.id })
        .then((projectStatus) => {
          projectStatusData.value = projectStatus;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            projectStatusData.value = undefined;
          }
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchProjectStatus();
      }
    });

    return {
      projectStatusData,
      onSubmit,
    };
  },
};
</script>

<style></style>
